import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import PostRepository from "@/abstraction/repository/postRepository";

const repository = new PostRepository();

export default function useUsersList() {
    // Use toast
    const toast = useToast();

    // const refUserListTable = ref(null)

    // Table Handlers
    const tableColumns = [{ key: "title", sortable: true }, { key: "user", sortable: true }, { key: "text", sortable: true }, { key: "created_at", sortable: true }, { key: "actions" }];
    const perPage = ref(10);
    const totalUsers = ref(0);
    const currentPage = ref(1);
    const pageStart = ref(1);
    const pagination = ref({});
    const storyPagination = ref({});
    const pageStop = perPage;
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const roleFilter = ref(null);
    const planFilter = ref(null);
    const statusFilter = ref(null);
    const posts = ref([]);
    const stories = ref([]);
    const contestPostId = ref();
    const fetchPost = async (paginate = { page: 1, itemsPerPage: perPage.value }, filters = {}) => {
        const postList = await repository.indexContestsPosts({ filters, pagination: paginate }, contestPostId.value);
        console.log(postList);
        posts.value = postList.data;
        pagination.value = postList.pagination;
        return posts;
    };
    const fetchStories = async (paginate = { page: 1, itemsPerPage: perPage.value }, filters = {}) => {
        const storyList = await repository.indexContestsStories({ filters, pagination: paginate }, contestPostId.value);
        console.log(storyList);
        stories.value = storyList.data;
        storyPagination.value = storyList.pagination;
        return stories;
    };

    const changeStatus = async (postId, data) => {
        await repository.verify(postId, data);
        await fetchPost();
    };

    const changePin = async (postId) => {
        await repository.pin(postId);
        await fetchPost();
    };

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveUserRoleVariant = (role) => {
        if (role === "subscriber") return "primary";
        if (role === "author") return "warning";
        if (role === "maintainer") return "success";
        if (role === "editor") return "info";
        if (role === "admin") return "danger";
        return "primary";
    };

    const resolveUserRoleIcon = (role) => {
        if (role === "subscriber") return "UserIcon";
        if (role === "author") return "SettingsIcon";
        if (role === "maintainer") return "DatabaseIcon";
        if (role === "editor") return "Edit2Icon";
        if (role === "admin") return "ServerIcon";
        return "UserIcon";
    };

    const resolveUserStatusVariant = (status) => {
        if (status === "pending") return "warning";
        if (status === "active") return "success";
        if (status === "inactive") return "secondary";
        return "primary";
    };

    return {
        fetchPost,
        tableColumns,
        perPage,
        pagination,
        pageStart,
        pageStop,
        currentPage,
        totalUsers,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        posts,
        stories,
        changeStatus,
        changePin,
        fetchStories,
        storyPagination,
        resolveUserRoleVariant,
        resolveUserRoleIcon,
        resolveUserStatusVariant,
        contestPostId,
        // Extra Filters
        roleFilter,
        planFilter,
        statusFilter,
    };
}
