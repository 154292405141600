<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>Filters</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
            cols="12"
            md="6"
            class="mb-md-0 mb-2"
        >
          <label>Title</label>
          <b-form-input
              id="title"
              v-model="form.title.val"
              class="d-inline-block mr-1"
          />
        </b-col>
        <b-col
            cols="12"
            md="6"
            class="mb-md-0 mb-2"
        >
          <label>Text</label>
          <b-form-input
              id="description"
              v-model="form.text.val"
              class="d-inline-block mr-1"
          />
        </b-col>
      </b-row>
      <b-row>
<!--        <b-col-->
<!--            cols="12"-->
<!--            md="6"-->
<!--            class="mb-md-0 mb-2"-->
<!--        >-->
<!--          <label>User</label>-->
<!--          <b-form-input-->
<!--              id="user"-->
<!--              v-model="form.user.val"-->
<!--              class="d-inline-block mr-1"-->
<!--          />-->
<!--        </b-col>-->
        <b-col
            cols="12"
            md="6"
            class="mb-md-0 mb-2"
        >
          <label>Status</label>
          <b-form-select
              id="status"
              v-model="form.status.val"
              class="d-inline-block mr-1"
          >
            <option value="" selected>All</option>
            <option value="undefined">Undefined</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
        >
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="danger"
              class="mt-1"
              @click="filterPost"
          >
            Filter
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormInput, BButton,BFormSelect
} from 'bootstrap-vue'
import {ref} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import usePostList from "@/views/apps/post/usePostList";
import {avatarText} from "@core/utils/filter";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BButton,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    roleFilter: {
      type: [String, null],
      default: null,
    },
    planFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const isLoading = ref()
    const {
      fetchPost,
      tableColumns,
      perPage,
      pagination,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      posts,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
    } = usePostList()

    const blankForm = {
      title: {
        type: 'like',
        val: null,
      },
      text: {
        type: 'like',
        val: null,
      },
      user: {
        type: 'like',
        val: null,
      },
      status: {
        type: 'like',
        val: null,
      },
    }
    const form = ref(blankForm)

    return {
      form,
      isLoading,
      // Sidebar
      fetchPost,
      pagination,
      tableColumns,
      perPage,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      posts,
      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
    }

  },
  methods:{
    filterPost() {
      this.$emit('filter', {page: 1, itemsPerPage: this.perPage}, this.form)
    }

  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
