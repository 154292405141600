<template>
    <div>
        <div>
            <!-- <b-card>
                <b-row class="justify-content-center my-3">
                    <b-col cols="4">
                        <b-row class="justify-content-center">
                            <h4>Choose a stage</h4>
                        </b-row>
                        <v-select v-model="stage_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.id" :options="stagesOption" :clearable="false" label="name" @input="fetchGroupHandler(stage_id)" />
                    </b-col>
                    <b-col cols="4">
                        <b-row class="justify-content-center">
                            <h4>Choose a group</h4>
                        </b-row>
                        <v-select v-model="group_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val" :options="groupOption" :clearable="false" label="name" @input="fetchGroupHandler(stage_id)" />
                    </b-col>
                </b-row>
                <b-row class="justify-content-center">
                    <b-button type="button" variant="danger" class="mt-1" @click="showFilterPostsHandler"> Show </b-button>
                </b-row>
            </b-card> -->
        </div>
        <div v-if="true">
            <post-list-filters :role-filter.sync="roleFilter" :plan-filter.sync="planFilter" :status-filter.sync="statusFilter" :role-options="roleOptions" :plan-options="planOptions" :status-options="statusOptions" @filter="fetchPost" />

            <!-- Table Container Card -->
            <b-tabs align="center" fill>
                <b-tab title="Posts">
                    <b-card no-body class="mb-0">
                        <div class="m-2">
                            <!-- Table Top -->
                            <b-row class="justify-content-between">
                                <!-- Per Page -->
                                <b-col cols="10" md="10" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                                    <label>Show</label>
                                    <b-form-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @change="changePagePost({ page: 1, itemsPerPage: $event })" />
                                    <label>entries</label>
                                </b-col>
                                <b-col cols="2" md="2">
                                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="openModalHandler"> Candidates List </b-button>
                                </b-col>
                            </b-row>
                        </div>
                        <b-table ref="refUserListTable" class="position-relative pb-3" :items="posts" :per-page="perPage" responsive :fields="tableColumns" primary-key="id" show-empty empty-text="No matching records found">
                            <!-- Column: Title -->
                            <template #cell(title)="data">
                                <b-media vertical-align="center">
                                    <template #aside>
                                        <!-- <b-avatar size="32" :src="data.item.file ? data.item.file.thumbnail : 'https://via.placeholder.com'" :text="avatarText(data.item.name)" :variant="`light-${resolveUserRoleVariant(data.item.role)}`" :to="{ name: 'apps-post-view', params: { id: data.item.id } }" /> -->
                                        <div v-if="data.item.file.mime_type == 'video/vimeo'" ref="playerContainer">
                                            <video id="videoPlayer" controls width="240" height="400" class="plyr">
                                                <source :src="data.item.file.download[1].link" type="video/mp4" />
                                            </video>
                                        </div>
                                        <div v-else-if="data.item.file == ''">no file...</div>
                                        <div v-else-if="data.item.file !== ''">
                                            <b-img width="240" height="200" thumbnail fluid :src="data.item.file ? data.item.file.thumbnail : 'https://via.placeholder.com'" />
                                        </div>
                                    </template>
                                    <!-- <b-link :to="{ name: 'apps-post-view', params: { id: data.item.id } }" class="font-weight-bold d-block text-nowrap">
                    {{ data.item.title }}
                </b-link> -->
                                    <!--            <small class="text-muted">{{ data.item.name }}</small>-->
                                    <feather-icon v-if="data.item.pined" icon="LinkIcon" />
                                </b-media>
                            </template>
                            <!-- Column: User -->
                            <template #cell(user)="data">
                                <b-media vertical-align="center">
                                    <template #aside>
                                        <b-avatar size="32" :src="data.item.user.thumbnail" :text="avatarText(data.item.name)" :variant="`light-${resolveUserRoleVariant(data.item.user.role)}`" :to="{ name: 'apps-users-view', params: { id: data.item.user.id } }" />
                                    </template>
                                    <b-link :to="{ name: 'apps-users-view', params: { id: data.item.user.id } }" class="font-weight-bold d-block text-nowrap">
                                        {{ data.item.user.name }}
                                    </b-link>
                                    <!--            <small class="text-muted">{{ data.item.name }}</small>-->
                                </b-media>
                            </template>
                            <!-- Column: Text -->
                            <template #cell(text)="data">
                                <div class="text-nowrap">
                                    <span class="align-text-top text-capitalize">{{ shortDescription(data.item.text) }}</span>
                                </div>
                                <!-- <div v-if="data.item.text = undefined">
                                    ....
                                </div> -->
                            </template>
                            <!-- Column: created at -->
                            <template #cell(created_at)="data">
                                <div class="text-nowrap">
                                    <span class="align-text-top text-capitalize">{{ data.item.created_at }}</span>
                                </div>
                            </template>
                            <!-- Column: Actions -->
                            <template #cell(actions)="data">
                                <div v-if="data.item.candidate.length < 1">
                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-info" @click="candidateHandler(data.item)">Candidate</b-button>
                                </div>
                                <div v-else>
                                    <b-badge variant="light-success" pill> This user candidated </b-badge>
                                </div>
                            </template>
                        </b-table>
                        <div class="mx-2 mb-2">
                            <b-row>
                                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                                    <span class="text-muted">Showing {{ pagination.pageStart }} to {{ pagination.pageStop }} of {{ pagination.itemsLength }} entries</span>
                                </b-col>
                                <!-- Pagination -->
                                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                                    <b-pagination v-model="currentPage" :total-rows="pagination.itemsLength" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="changePagePost({ page: $event, itemsPerPage: perPage })">
                                        <template #prev-text>
                                            <feather-icon icon="ChevronLeftIcon" size="18" />
                                        </template>
                                        <template #next-text>
                                            <feather-icon icon="ChevronRightIcon" size="18" />
                                        </template>
                                    </b-pagination>
                                </b-col>
                            </b-row>
                        </div>
                    </b-card>
                </b-tab>
                <b-tab title="Story">
                    <b-card no-body class="mb-0">
                        <div class="m-2">
                            <!-- Table Top -->
                            <b-row class="justify-content-between">
                                <!-- Per Page -->
                                <b-col cols="10" md="10" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                                    <label>Show</label>
                                    <b-form-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @change="changePagePost({ page: 1, itemsPerPage: $event })" />
                                    <label>entries</label>
                                </b-col>
                                <b-col cols="2" md="2">
                                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="openModalHandler"> Candidates List </b-button>
                                </b-col>
                            </b-row>
                        </div>
                        <b-table ref="refUserListTable" class="position-relative pb-3" :items="stories" :per-page="perPage" responsive :fields="tableColumns" primary-key="id" show-empty empty-text="No matching records found">
                            <!-- Column: Title -->
                            <template #cell(title)="data">
                                <b-media vertical-align="center">
                                    <template #aside>
                                        <div v-if="data.item.video.mime_type == 'video/vimeo'" ref="playerContainer">
                                            <video id="videoPlayer" controls width="240" height="400" class="plyr">
                                                <source :src="data.item.video.download[1].link" type="video/mp4" />
                                            </video>
                                        </div>
                                        <div v-else-if="data.item.video == ''">no file...</div>
                                        <div v-else-if="data.item.video !== ''">
                                            <b-img width="240" height="200" thumbnail fluid :src="data.item.video ? data.item.video.thumbnail : 'https://via.placeholder.com'" />
                                        </div>
                                    </template>
                                    <feather-icon v-if="data.item.pined" icon="LinkIcon" />
                                </b-media>
                            </template>
                            <!-- Column: User -->
                            <template #cell(user)="data">
                                <b-media vertical-align="center">
                                    <template #aside>
                                        <b-avatar size="32" :src="data.item.user.thumbnail" :text="avatarText(data.item.name)" :variant="`light-${resolveUserRoleVariant(data.item.user.role)}`" :to="{ name: 'apps-users-view', params: { id: data.item.user.id } }" />
                                    </template>
                                    <b-link :to="{ name: 'apps-users-view', params: { id: data.item.user.id } }" class="font-weight-bold d-block text-nowrap">
                                        {{ data.item.user.name }}
                                    </b-link>
                                    <!--            <small class="text-muted">{{ data.item.name }}</small>-->
                                </b-media>
                            </template>
                            <!-- Column: Text -->
                            <template #cell(text)="data">
                                <div class="text-nowrap">
                                    <span class="align-text-top text-capitalize">{{ shortDescription(data.item.text) }}</span>
                                </div>
                            </template>
                            <!-- Column: created at -->
                            <template #cell(created_at)="data">
                                <div class="text-nowrap">
                                    <span class="align-text-top text-capitalize">{{ data.item.created_at }}</span>
                                </div>
                            </template>
                            <!-- Column: Actions -->
                            <template #cell(actions)="data">
                                <div v-if="data.item.candidate.length < 1">
                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-info" @click="storyCandidateHandler(data.item)">Candidate</b-button>
                                </div>
                                <div v-else>
                                    <b-badge variant="light-success" pill> This user candidated </b-badge>
                                </div>
                            </template>
                        </b-table>
                        <div class="mx-2 mb-2">
                            <b-row>
                                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                                    <span class="text-muted">Showing {{ storyPagination.pageStart }} to {{ storyPagination.pageStop }} of {{ storyPagination.itemsLength }} entries</span>
                                </b-col>
                                <!-- Pagination -->
                                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                                    <b-pagination v-model="currentPage" :total-rows="storyPagination.itemsLength" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="changePageStory({ page: $event, itemsPerPage: perPage })">
                                        <template #prev-text>
                                            <feather-icon icon="ChevronLeftIcon" size="18" />
                                        </template>
                                        <template #next-text>
                                            <feather-icon icon="ChevronRightIcon" size="18" />
                                        </template>
                                    </b-pagination>
                                </b-col>
                            </b-row>
                        </div>
                    </b-card>
                </b-tab>
            </b-tabs>
            <b-modal id="modal-xl" ok-only ok-title="Accept" centered size="xl" title="Extra Large Modal" v-model="openModal">
                <b-row class="justify-content-center my-3">
                    <b-col cols="4">
                        <b-row class="justify-content-center">
                            <h4>Choose a stage</h4>
                        </b-row>
                        <v-select v-model="stage_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.id" :options="stagesOption" :clearable="false" label="name" @input="fetchGroupHandler(stage_id)" />
                    </b-col>
                    <b-col cols="4">
                        <b-row class="justify-content-center">
                            <h4>Choose a group</h4>
                        </b-row>
                        <v-select v-model="group_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val" :options="groupOption" :clearable="false" label="name" @input="fetchGroupHandler(stage_id)" />
                    </b-col>
                </b-row>
                <b-table ref="refUserListTable" class="position-relative pb-3" :items="candidates" responsive :fields="modalTableColumns" primary-key="id" show-empty empty-text="No matching records found">
                    <!-- Column: Title -->
                    <template #cell(title)="data">
                        <b-media vertical-align="center">
                            <template #aside>
                                <div v-if="data.item.post" class="text-center">
                                    <h4>Post</h4>
                                    <div v-if="data.item.post.file.mime_type == 'video/vimeo'" ref="playerContainer">
                                        <video id="videoPlayer" controls width="240" height="400" class="plyr">
                                            <source :src="data.item.post.file.download[1].link" type="video/mp4" />
                                        </video>
                                    </div>
                                    <div v-else-if="data.item.post.file == ''">no file...</div>
                                    <div v-else-if="data.item.post.file !== ''">
                                        <b-img width="240" height="200" thumbnail fluid :src="data.item.post.file ? data.item.post.file.thumbnail : 'https://via.placeholder.com'" />
                                    </div>
                                </div>
                                <div v-if="data.item.story" class="text-center">
                                    <h4>Story</h4>
                                    <div v-if="data.item.story.video.mime_type == 'video/vimeo'" ref="playerContainer">
                                        <video id="videoPlayer" controls width="240" height="400" class="plyr">
                                            <source :src="data.item.story.video.download[1].link" type="video/mp4" />
                                        </video>
                                    </div>
                                    <div>
                                        <h5 class="text-warning">Video not available</h5>
                                    </div>
                                    <!-- <div v-else-if="data.item.story.file == ''">no file...</div>
                                    <div v-else-if="data.item.story.file !== ''">
                                        <b-img width="240" height="200" thumbnail fluid :src="data.item.post.file ? data.item.post.file.thumbnail : 'https://via.placeholder.com'" />
                                    </div> -->
                                </div>
                            </template>
                        </b-media>
                    </template>
                    <!-- Column: User -->
                    <template #cell(user)="data">
                        <b-media vertical-align="center">
                            <template #aside>
                                <b-avatar size="32" :src="data.item.user.thumbnail" :text="avatarText(data.item.name)" :variant="`light-${resolveUserRoleVariant(data.item.user.role)}`" :to="{ name: 'apps-users-view', params: { id: data.item.user.id } }" />
                            </template>
                            <b-link :to="{ name: 'apps-users-view', params: { id: data.item.user.id } }" class="font-weight-bold d-block text-nowrap">
                                {{ data.item.user.name }}
                            </b-link>
                            <!--            <small class="text-muted">{{ data.item.name }}</small>-->
                        </b-media>
                    </template>
                    <!-- Column: Text -->
                    <template #cell(text)="data">
                        <div class="text-nowrap">
                            <span class="align-text-top text-capitalize">{{ shortDescription(data.item.text) }}</span>
                        </div>
                    </template>
                    <!-- Column: created at -->
                    <template #cell(created_at)="data">
                        <div class="text-nowrap">
                            <span class="align-text-top text-capitalize">{{ data.item.created_at }}</span>
                        </div>
                    </template>
                    <!-- Column: Actions -->
                    <template #cell(actions)="data">
                        <div class="row">
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-success" @click="participantHandler(data.item)">Participant</b-button>
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="ml-50" variant="outline-danger" @click="removeCandidateHandler(data.item.id)">Remove</b-button>
                        </div>
                    </template>
                </b-table>
                <div class="mx-2 mb-2">
                    <b-row>
                        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                            <span class="text-muted">Showing {{ candidatePagination.pageStart }} to {{ candidatePagination.pageStop }} of {{ candidatePagination.itemsLength }} entries</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                            <b-pagination v-model="currentPage" :total-rows="candidatePagination.itemsLength" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="changePageStory({ page: $event, itemsPerPage: perPage })">
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
import { BModal, VBModal, BFormCheckbox, BFormCheckboxGroup, BImg, BCard, BRow, BCol, BFormInput, BFormSelect, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BTabs, BTab } from "bootstrap-vue";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import PostRepository from "@/abstraction/repository/postRepository";
import PostListFilters from "./PostListFilters.vue";
import usePostList from "./usePostList";
import userStoreModule from "../../user/userStoreModule";
import VideoPlayer from "@/views/components/video/VideoPlayer";
import vSelect from "vue-select";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import useContestList from "../useContestList";
import ContestRepository from "@/abstraction/repository/contestRepository";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
const contestRepository = new ContestRepository();

// repository
const postRepository = new PostRepository();
export default {
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    components: {
        BModal,
        VBModal,
        BFormCheckbox,
        BFormCheckboxGroup,
        PostListFilters,
        BImg,
        VideoPlayer,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BFormSelect,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        vSelect,
        BTabs,
        BTab,
    },
    data() {
        return {
            form: {
                description: null,
            },
        };
    },

    setup(props, context) {
        const USER_APP_STORE_MODULE_NAME = "app-user";

        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
        });

        const roleOptions = [
            {
                label: "Admin",
                value: "admin",
            },
            {
                label: "Author",
                value: "author",
            },
            {
                label: "Editor",
                value: "editor",
            },
            {
                label: "Maintainer",
                value: "maintainer",
            },
            {
                label: "Subscriber",
                value: "subscriber",
            },
        ];

        const planOptions = [
            {
                label: "Basic",
                value: "basic",
            },
            {
                label: "Company",
                value: "company",
            },
            {
                label: "Enterprise",
                value: "enterprise",
            },
            {
                label: "Team",
                value: "team",
            },
        ];

        const statusOptions = [
            {
                label: "Pending",
                value: "pending",
            },
            {
                label: "Active",
                value: "active",
            },
            {
                label: "Inactive",
                value: "inactive",
            },
        ];
        const {
            fetchPost,
            fetchStories,
            changeStatus,
            changePin,
            tableColumns,
            perPage,
            pagination,
            storyPagination,
            currentPage,
            totalUsers,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refUserListTable,
            posts,
            stories,
            contestPostId,
            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,

            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
        } = usePostList();
        const videoOptions = ref({
            autoplay: true,
            controls: true,
            height: 250,
            width: 350,
            sources: [
                {
                    src: "https://nojoum-videos.s3.me-central-1.amazonaws.com/User-uploads/000000007055/1696494130/transcoded/Sl5bijoYGhQbdonBIleVfEDdDbW6IbHBUEdqwgO0RkwA--transcoded-480p.mp4",
                    type: "application/x-mpegURL",
                },
            ],
            poster: "",
        });
        onMounted(async () => {
            contestPostId.value = router.currentRoute.params.id;

            await fetchPost().then((res) => {
                posts.value.map((item) => {
                    return (item.created_at = item.created_at.slice(0, -9));
                });
            });
            await fetchStories().then((res) => {
                stories.value.map((item) => {
                    return (item.created_at = item.created_at.slice(0, -9));
                });
            });
        });

        const changePagePost = (paginate) => {
            fetchPost(paginate).then((res) => {
                posts.value.map((item) => {
                    return (item.created_at = item.created_at.slice(0, -9));
                });
            });
        };
        const changePageStory = (paginate) => {
            fetchStories(paginate).then((res) => {
                stories.value.map((item) => {
                    return (item.created_at = item.created_at.slice(0, -9));
                });
            });
        };
        //contests
        const chosen = ref();
        const { fetchGroupContest, storeCandidates, contestCandidatesList, removeCandidate, secondContestPostId, candidatePagination, candidates } = useContestList();

        const contestsOption = ref([]);
        const stagesOption = ref();
        const groupOption = ref([]);
        const contestvalue = ref();
        const stage_id = ref();
        const group_id = ref();
        const openModal = ref(false);
        // contestRepository.index().then((res) => {
        //     contestsOption.value = res.data;
        // });

        const fetchStageList = () => {
            contestRepository.fetchStageContest(router.currentRoute.params.id).then((res) => {
                stagesOption.value = res.data;
            });
        };
        fetchStageList();
        const fetchGroupHandler = (id) => {
            fetchGroupContest(id).then((res) => {
                groupOption.value = res.value;
            });
        };
        const showFilterPostsHandler = () => {
            let form = {
                title: {
                    type: "like",
                    val: null,
                },
                text: {
                    type: "like",
                    val: contestvalue.value.name,
                },
                user: {
                    type: "like",
                    val: null,
                },
                status: {
                    type: "like",
                    val: null,
                },
            };
            fetchPost({ page: 1, itemsPerPage: perPage.value }, form);
        };
        const chosenHandler = () => {
            let data = [
                {
                    stage_id: 0,
                    group_id: 0,
                    user_id: 0,
                    contest_post_id: 0,
                    contest_bio: "string",
                    contest_bio_link: "string",
                    status: "candidate",
                },
            ];
        };
        const modalTableColumns = ref([{ key: "title", sortable: true }, { key: "user", sortable: true }, { key: "text", sortable: true }, { key: "created_at", sortable: true }, { key: "actions" }]);
        const candidateItems = ref();
        const candidateHandler = (candid) => {
            console.log("HElll");
            let data = {
                user_id: candid.user.id,
                contest_post_id: candid.id,
                status: "candidate",
            };
            storeCandidates(data, router.currentRoute.params.id);
        };

        const storyCandidateHandler = (candid) => {
            let data = {
                user_id: candid.user.id,
                contest_story_id: candid.id,
                status: "candidate",
            };
            storeCandidates(data, router.currentRoute.params.id);
        };
        const openModalHandler = async () => {
            secondContestPostId.value = router.currentRoute.params.id;

            await contestCandidatesList().then((res) => {
                console.log(res);
                console.log("SS", candidates);
                // candidateItems.value = res;
            });
            openModal.value = !openModal.value;
        };
        const participantHandler = (dataItems) => {
            console.log(dataItems);
            if (stage_id.value && group_id.value.id) {
                let data = {
                    stage_id: stage_id.value,
                    group_id: group_id.value.id,
                    user_id: dataItems.user_id,
                    status: "participant",
                };
                if(dataItems.post){
                    data.contest_post_id = dataItems.post.id
                };
                if(dataItems.story){
                    data.contest_story_id = dataItems.story.id
                };
                console.log(data);
                storeCandidates(data, router.currentRoute.params.id).then((res) => {
                    console.log(res);
                });
            } else {
                context.root.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "ERROR",
                        icon: "BellIcon",
                        text: "Please select stage and group first !!",
                        variant: "danger",
                    },
                });
            }
        };
        const removeCandidateHandler = async (id) => {
            console.log(id);
            removeCandidate(id);
        };
        return {
            // Sidebar
            fetchPost,
            videoOptions,
            posts,
            pagination,
            tableColumns,
            perPage,
            currentPage,
            totalUsers,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refUserListTable,
            changePagePost,
            changePageStory,
            changeStatus,
            changePin,
            // Filter
            avatarText,
            stories,
            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,
            roleOptions,
            planOptions,
            statusOptions,
            storyPagination,
            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
            //contest
            fetchGroupHandler,
            // fetchStageList,
            contestvalue,
            stage_id,
            contestsOption,
            stagesOption,
            group_id,
            groupOption,
            showFilterPostsHandler,
            chosen,
            chosenHandler,
            candidateHandler,
            openModal,
            openModalHandler,
            modalTableColumns,
            candidateItems,
            participantHandler,
            removeCandidateHandler,
            storyCandidateHandler,
            candidates,
            candidatePagination,
        };
    },
    methods: {
        async deletePost(postId) {
            await postRepository.delete(postId);
            const index = this.posts.findIndex((x) => x.id === postId);
            this.$delete(this.posts, index);
        },
        shortDescription(value) {
            if (value) {
                const words = value.split(" ");
                const wordsSliced = words.slice(0, 9);
                if (words.length > 10) {
                    return `${wordsSliced.join(" ")}...`;
                }
                return wordsSliced.join(" ");
            }
            return value;
        },
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

a {
    color: $red;
}
</style>
